import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { chnagePassword } from "../Redux/Actions/AdminAuth";

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("Required"),
  newPassword: yup
    .string()
    .min(8, "New password must be at least 8 characters long")
    .matches(
      /[a-z]/,

      "Must contain at least one lowercase letter"
    )
    .matches(
      /[A-Z]/,

      "  Must contain at least one uppercase letter"
    )
    .matches(/[0-9]/, " Must contain at least one number")
    .matches(
      /[\W_]/,

      "Must contain at least one special character"
    )
    .required("Required"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});

export default function ChangePassword() {

  const dispatch = useDispatch();

  const initialvalues = {
    oldPassword: "",
    newPassword: "",
    confirmpassword: "",
  };

  const handleChangepassword = (values, resetForm) => {
    dispatch(
      chnagePassword({
        oldPassword: values?.oldPassword,
        newPassword: values?.newPassword,
      })
    )
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/";
          }, 1500);
          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="user-search">
          <div className="edit-profile-page">
            <div className="edit-flex-text">
              <div className="main-heading">
                <h2>Change password</h2>
              </div>
            </div>

            <Formik
              initialValues={initialvalues}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) =>
                handleChangepassword(values, resetForm)
              }
            >
              {({ values, handleChange, handleSubmit, resetForm }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="edit-input mt-4">
                    <div className="user-info">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="password"
                          placeholder="Current password"
                          name="oldPassword"
                          value={values?.oldPassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="oldPassword"  className="error-message" component="div" />
                      </Form.Group>
                    </div>
                    <div className="user-info">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="password"
                          placeholder="New password"
                          name="newPassword"
                          value={values?.newPassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="newPassword" className="error-message" component="div" />
                      </Form.Group>
                    </div>
                    <div className="user-info">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="password"
                          placeholder="Confirm password"
                          name="confirmpassword"
                          value={values?.confirmpassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="confirmpassword" className="error-message" component="div" />
                      </Form.Group>
                    </div>

                    <button variant="primary" type="submit" className="submit">
                      Update Password
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
