import React from 'react'
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { BlockActiveDriver, deleteDriver } from '../Redux/Actions/DriverActions';
import { useNavigate } from 'react-router-dom';

const ConfirmModal = ({ handleConfirmModalClose, confirmModal, choosenType, idVal, data }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleBlockDriver = async () => {
        dispatch(BlockActiveDriver({ userId: idVal, type: data === 0 ? 1 : 0 })).then((res) => {
            console.log(res);
            if (res?.payload?.data?.status === 200) {
                toast.success(res?.payload?.data?.message);
                handleConfirmModalClose()
            }
        }).catch((error) => {
            console.log(error);
        })

    }


    const handleDeleteDriver = async () => {
        dispatch(deleteDriver({ userId: idVal })).then((res) => {
            console.log(res);
            if (res?.payload?.data?.status === 200) {
                toast.success(res?.payload?.data?.message);
                navigate("/active-driver")
            }
        }).catch((error) => {
            console.log(error);
        })
    }



    return (
        <div>
            <Modal show={confirmModal} onHide={handleConfirmModalClose} centered>
                <Modal.Header closeButton>
                    {choosenType === "blockDriver" && <Modal.Title>Block Driver</Modal.Title>}

                    {choosenType === "activeDriver" && <Modal.Title>Active Driver</Modal.Title>}

                    {choosenType === "deleteDriver" && <Modal.Title>Delete Driver</Modal.Title>}

                </Modal.Header>
                {choosenType === "blockDriver" && <Modal.Body>Are you sure you want to Block !</Modal.Body>}

                {choosenType === "activeDriver" && <Modal.Body>Are you sure you want to Active !</Modal.Body>}
                {choosenType === "deleteDriver" && <Modal.Body>Are you sure you want to delete !</Modal.Body>}

                <Modal.Footer>
                    <Button className="new-secondary" variant="secondary" onClick={handleConfirmModalClose}>
                        No
                    </Button>
                    {choosenType === "blockDriver" && <Button className="new-danger" variant="danger" onClick={handleBlockDriver}>
                        Yes
                    </Button>}
                    {choosenType === "deleteDriver" && <Button className="new-danger" variant="danger" onClick={handleDeleteDriver}>
                        Yes
                    </Button>}


                    {choosenType === "activeDriver" && <Button className="new-danger" variant="danger" onClick={handleBlockDriver}>
                        Yes
                    </Button>}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ConfirmModal
