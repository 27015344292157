import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import EditProfile from "./Pages/EditProfile";
import ChangePassword from "./Pages/ChangePassword";
import Passengers from "./Pages/Passengers";
import ResetPassword from "./Pages/ResetPassword";
import Driver from "./Pages/Driver";
import FleetOwnerRequest from "./Pages/FleetOwnerRequest";
import Active from "./Pages/Active";
import ForgotPassword from "./Pages/ForgotPassword";
import { Provider } from "react-redux";
import { ProfileProvider } from "./Context/ProfileContext";
import ActiveDriver from "./Pages/ActiveDrivers";
import ProtectedRoutes from "./Components/Layout/ProtectedRoutes";
import DriversNewRequestsDetail from "./Pages/DriversNewRequestsDetail";
import ActiveDriverDetails from "./Pages/ActiveDriverDetails";
import FleetNewRequestsDetail from "./Pages/FleetRequestDetails";
import VehiclesRequest from "./Pages/VehiclesRequest";
import CarsRequest from "./Pages/VehiclesRequest";
import CarDetails from "./Pages/Vehicledetails";
import VehicleDetails from "./Pages/Vehicledetails";
import FleetActiveDetail from "./Pages/FleetActiveDetail";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ProfileProvider>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/reset-password" element={<ResetPassword />}></Route>

            <Route element={<ProtectedRoutes />}>
              <Route path="/edit-profile" element={<EditProfile />}></Route>
              <Route
                path="/change-password"
                element={<ChangePassword />}
              ></Route>
              <Route path="/passengers" element={<Passengers />}></Route>
              <Route path="/driver" element={<Driver />}></Route>
              <Route path="/active-driver" element={<ActiveDriver />}></Route>
              <Route
                path="/drivers-new-requests-detail/:id"
                element={<DriversNewRequestsDetail />}
              ></Route>
              <Route
                path="/active-driver-details/:id"
                element={<ActiveDriverDetails />}
              ></Route>
              <Route
                path="/fleet-owner-request"
                element={<FleetOwnerRequest />}
              ></Route>
              <Route path="/active" element={<Active />}></Route>
              <Route
                path="/fleet-owner-details/:id"
                element={<FleetNewRequestsDetail />}
              ></Route>
            </Route>
            <Route
              path="/vehicle-new-request"
              element={<VehiclesRequest />}
            ></Route>
            <Route
              path="/vehicle-details/:id"
              element={<VehicleDetails />}
            ></Route>
            <Route
              path="/fleet-active-detail/:id"
              element={<FleetActiveDetail />}
            ></Route>
          </Routes>
        </ProfileProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
