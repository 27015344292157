import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";

  // ====== For passengerManagement =====

  export const passengerManagement = createAsyncThunk(
    "passengerManagement",
    async (details) => {
      let url = `/passengerManagement?page=${details?.page}&limit=${details?.limit}`;
  
      if (details.search) {
        url += `&name=${details.search}`;
      }
  
      const data = await AdminAPI.get(url);
      console.log(data);
      return data;
    }
  );