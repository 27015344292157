import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Pagination from "react-bootstrap/Pagination";
import {
  FleetOwnerManagement,
  fleetownerRequests,
} from "../Redux/Actions/FleetOwnerAction";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponet from "../Components/Layout/Pagination";

export default function Active() {
  const fleetdata = useSelector((state) => state.fleet?.alldata);
  console.log(fleetdata);
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [type, settype] = useState("0");
  const [flag, setflag] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      FleetOwnerManagement({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
      type: type,
    };
    dispatch(FleetOwnerManagement(searchData));
  }, [page, limit, searchValue, flag, type, dispatch]);


  const handlenavigate = (id) => {
    navigate(`/fleet-active-detail/${id}`)
  }

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Active Fleet Owner</h2>
            </div>
            <div className="search-tab ">
              <div className="input-search full-input-width">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      placeholder="Search "
                      name="searchvalue"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="user-search-passengers">
              <div className="drop-down">
                <div className="toggle-box-top">
                  {/* First toggle (Images) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "images" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("images");
                      settype("0");
                    }} // Set active state to 'images' on click
                  >
                    <p>Active</p>
                  </div>

                  {/* Second toggle (HEIC To JPG) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "heicToJpg" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("heicToJpg");
                      settype(1);
                    }} // Set active state to 'heicToJpg' on click
                  >
                    <p>Blocked</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone no.</th>
                    <th>No. of vechicles</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fleetdata?.fleets?.map((res, index) => {
                    const serialNumber = (page - 1) * limit + index + 1;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <div className="user-id">
                                <p>{res?.company_name || "N/A"}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>{res?.company_email || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>
                              {" "}
                              {res?.country_code && res?.phone_number
                                ? `${res.country_code} ${res.phone_number}`
                                : res?.country_code
                                ? res.country_code
                                : res?.phone_number
                                ? res.phone_number
                                : "N/A"}{" "}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="user-id">
                            <p>{res?.total_vehicle || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-view">
                            <img
                              src={
                                require("../Assets/Images/view-icon.svg")
                                  .default
                              }
                              onClick={() => handlenavigate(res?._id)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p> Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, fleetdata?.totalCount)} of{" "}
                    {fleetdata?.totalCount} results</p>
                </div>
                <div className="pagination-block">
                <PaginationComponet
                    currentPage={page}
                    totalCount={fleetdata?.totalCount}
                    limit={fleetdata?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
