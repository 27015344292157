import React, { useState } from "react";
const BottomFoot = () => {
  return (
    <div>
      <div className="footer-sec">
        <p>Copyright 2024. All right reserved to VibeOnn</p>
      </div>
    </div>
  );
};

export default BottomFoot;
