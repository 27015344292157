import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import * as yup from "yup";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { editAdminprofile, getAdmindetails } from "../Redux/Actions/AdminAuth";
import CountryCode from "../../src/CountryCode.json";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { ProfileContext } from "../Context/ProfileContext";

const validationschemaforEditdetails = yup.object({
  admin_name: yup.string().required("Required"),
  profile_image: yup.string().required("Required"),
  email: yup.string().email("Invalid email address").required("Required"),

  phone_number: yup
    .string()
    .required("Required")
    .matches(/^\d+$/, "Phone number must be digits only")
    .min(7, "Phone number must be at least 7 digits long"),
});

export default function EditProfile() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [admindetails, setAdminDetails] = useState({});
  const [imagePreview, setImagePreview] = useState("");
  const [curruntImage, setCurruntImage] = useState("");
  const {profileData , setProfileData} = useContext(ProfileContext);

  const [flag, setflag] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAdmindetails())
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setCurruntImage(response?.payload?.data?.data?.profile_image);

          setAdminDetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  const initialvaluesEditdetails = {
    admin_name: "",
    email: "",
    country_code: "",
    phone_number: "",
    profile_image: null,
  };

  const handleProfileedit = (values, resetForm) => {
    const formData = new FormData();
    formData.append("admin_name", values.admin_name);
    formData.append("email", values.email);
    formData.append("country_code", values.country_code);
    formData.append("phone_number", values.phone_number);
    if (values.profile_image) {
    formData.append("profile_image", values.profile_image);

    }
    dispatch(editAdminprofile(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setAdminDetails((prevState) => ({
            ...prevState,
            ...res.payload.data.data,
          }));
          toast.success(res?.payload?.data?.message);
          resetForm();
          setProfileData(!profileData)
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      // setCurruntImage("");
      setFieldValue("profile_image", file);
      const url = URL.createObjectURL(file);
      setImagePreview(url);
    }
  };


  return (
    <Layout>
      <Container fluid>
        <div className="user-search">
          <Formik
            initialValues={admindetails || initialvaluesEditdetails}
            enableReinitialize={true}
            validationSchema={validationschemaforEditdetails}
            onSubmit={(values, { resetForm }) =>
              handleProfileedit(values, resetForm)
            }
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="edit-profile-page">
                  <div className="edit-flex-text">
                    <div className="main-heading">
                      <h2>Edit profile</h2>
                    </div>
                  </div>
                  <div className="user-profile-image">
                    {values?.profile_image instanceof Blob ? (
                      <>
                      <img
                        src={URL.createObjectURL(values?.profile_image)}
                        alt="Profile Preview"
                      />
                      </>
                    ) : (
                      <>
                      <img
                        src={`${url}${values?.profile_image}`}
                        alt="Current Profile"
                      />
                      </>
                    )}
                    <input
                      id="profileImage"
                      name="profile_image"
                      type="file"
                      accept=".jpg, .jpeg, .png, .gif"
                      onChange={(event) =>
                        handleFileChange(event, setFieldValue)
                      }
                      style={{ display: "none" }}
                    />
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        document.getElementById("profileImage").click()
                      }
                      className="btn-upload"
                    >
                      Update profile picture
                    </p>
                  </div>
                  <div className="edit-input"> 
                  
                    
                    <div className="user-info">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Your Name"
                          name="admin_name"
                          onChange={handleChange}
                          value={values?.admin_name}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="admin_name"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                    <div className="user-info">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                          readOnly
                        />
                        <ErrorMessage
                          className="error-message"
                          name="email"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                    <div className="user-info">
                      <div className="number-drop">
                        <div className="input-number">
                          <Form.Group
                            className="mb-2 drop-with-number"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="tel"
                              placeholder="Phone Number"
                              name="phone_number"
                              value={values?.phone_number}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <ErrorMessage
                            className="error-message"
                            name="phone_number"
                            component="div"
                          />
                          <div className="new-select-box">
                            <Form.Select
                              className="me-2"
                              style={{ maxWidth: "120px" }}
                              name="country_code"
                              value={values?.country_code}
                              onChange={(e) =>
                                setFieldValue("country_code", e.target.value)
                              }
                            >
                              {CountryCode?.map(({ dial_code, name, flag }) => (
                                <option key={dial_code} value={dial_code}>
                                  {flag} {dial_code}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button variant="primary" type="submit" className="submit">
                      Save Changes
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </Layout>
  );
}
