import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";

export const login = createAsyncThunk("login", async (details) => {
  const { data } = await AdminAPI.post("/login", details);
  console.log(data, "datadata");
  sessionStorage.setItem("token", data?.data?.token);
  return data;
});

// ==================== edit admin profile ======================

export const editAdminprofile = createAsyncThunk(
  "editAdminprofile",
  async (details) => {
    const data = await AdminAPI.put(`/editProfile`, details);
    console.log(data);
    return data;
  }
);

// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
  "chnagePassword",
  async (details) => {
    const data = await AdminAPI.patch(`/changePassword`, details);
    console.log(data);
    return data;
  }
);

// ==== get admin details to edit profile ====

export const getAdmindetails = createAsyncThunk("getAdmindetails", async () => {
  const data = await AdminAPI.get(`/getProfile`);
  console.log(data);
  return data;
});


// ========= Forgot Password =========

export const forgetPass = createAsyncThunk(
    "forgetPass",
    async(details) => {
        const data = await AdminAPI.post(`/forgetPassword`,details);
       return data;
    } 
  );

  // ======= Reset Password ===========

export const ResetPass = createAsyncThunk(
    "ResetPass",
    async(details) => {
        const data = await AdminAPI.post(`/resetpassword`,details);
       return data;
    } 
  );



