import React, { useContext, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAdmindetails } from "../Redux/Actions/AdminAuth";
import { ProfileContext } from "../Context/ProfileContext";
const TopHeader = () => {
  const navigate = useNavigate();

  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [admindetails, setAdmindetails] = useState("");
  const dispatch = useDispatch();

  const { profileData } = useContext(ProfileContext);

  useEffect(() => {
    dispatch(getAdmindetails())
      .then((response) => {
        if (response?.payload?.status === 200) {
          setAdmindetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {});
  }, [profileData]);

  const handlebtn = () => {
    navigate("/edit-profile");
  };

  return (
    <div>
      <Container fluid className="g-0">
        <div className="dashboard-items ">
          <div className="right-top-bar">
            <div className="left-side-bar">
              <button>
                <img src={require("../Assets/Images/hamburger.svg").default} />
              </button>
            </div>
            <div className="right-side-bar">
              <div className="notify-icon">
                <div className="notification-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 2C16.9706 2 21 6.04348 21 11.0314V20H3V11.0314C3 6.04348 7.02944 2 12 2ZM9.5 21H14.5C14.5 22.3807 13.3807 23.5 12 23.5C10.6193 23.5 9.5 22.3807 9.5 21Z"
                      fill="#696969"
                    />
                  </svg>
                </div>
              </div>
              <div className="user-img-top">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="user-profile-icon">
                      <img src={`${url}${admindetails?.profile_image}`} />
                      <p>
                        {admindetails?.admin_name || "N/A"}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 13.334L5 8.33398H15L10 13.334Z"
                              fill="#121212"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="my-profile">
                      <Dropdown.Item onClick={handlebtn}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <ellipse
                            cx="10.9999"
                            cy="6.41667"
                            rx="3.66667"
                            ry="3.66667"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        Edit profile
                      </Dropdown.Item>
                    </div>

                    <div className="my-profile"></div>
                    <Dropdown.Divider />
                    <div className="my-profile">
                      <Dropdown.Item href="/change-password">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M11.875 6.25H12.5C12.8452 6.25 13.125 6.52981 13.125 6.875V13.125C13.125 13.4702 12.8452 13.75 12.5 13.75H2.5C2.15483 13.75 1.875 13.4702 1.875 13.125V6.875C1.875 6.52981 2.15483 6.25 2.5 6.25H3.125V5.625C3.125 3.20876 5.08376 1.25 7.5 1.25C9.91625 1.25 11.875 3.20876 11.875 5.625V6.25ZM3.125 7.5V12.5H11.875V7.5H3.125ZM6.875 8.75H8.125V11.25H6.875V8.75ZM10.625 6.25V5.625C10.625 3.89911 9.22587 2.5 7.5 2.5C5.77411 2.5 4.375 3.89911 4.375 5.625V6.25H10.625Z"
                            fill="#121212"
                          />
                        </svg>
                        Change password
                      </Dropdown.Item>
                    </div>
                    <Dropdown.Divider />
                    <div className="my-profile">
                      <Dropdown.Item
                        href="/"
                        className="logout-btn"
                        onClick={() => sessionStorage.clear()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M2.5 11.25H3.75V12.5H11.25V2.5H3.75V3.75H2.5V1.875C2.5 1.52983 2.77982 1.25 3.125 1.25H11.875C12.2202 1.25 12.5 1.52983 12.5 1.875V13.125C12.5 13.4702 12.2202 13.75 11.875 13.75H3.125C2.77982 13.75 2.5 13.4702 2.5 13.125V11.25ZM3.75 6.875H8.125V8.125H3.75V10L0.625 7.5L3.75 5V6.875Z"
                            fill="#FF0000"
                          />
                        </svg>
                        Logout
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                        >
                          <path
                            d="M3.41659 12.8333C3.26188 12.8333 3.1135 12.7718 3.00411 12.6624C2.89471 12.553 2.83325 12.4047 2.83325 12.25V1.74996C2.83325 1.59525 2.89471 1.44688 3.00411 1.33748C3.1135 1.22808 3.26188 1.16663 3.41659 1.16663H11.5833C11.738 1.16663 11.8863 1.22808 11.9957 1.33748C12.1051 1.44688 12.1666 1.59525 12.1666 1.74996V3.49996H10.9999V2.33329H3.99992V11.6666H10.9999V10.5H12.1666V12.25C12.1666 12.4047 12.1051 12.553 11.9957 12.6624C11.8863 12.7718 11.738 12.8333 11.5833 12.8333H3.41659ZM10.9999 9.33329V7.58329H6.91659V6.41663H10.9999V4.66663L13.9166 6.99996L10.9999 9.33329Z"
                            fill="white"
                          />
                        </svg>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopHeader;
