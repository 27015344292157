import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  const [dropdowns, setDropdowns] = useState({ userDropdown: false });
  const navigate = useNavigate();

  const location = useLocation();
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (location.pathname == "/fleet-owner-request") {
      setDropdowns(() => ({
        userDropdown: true,
      }));
    }
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdowns({ userDropdown: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionSelect = (path) => {
    navigate(path);
    // If navigating to "New requests", close the dropdown
    if (path === "/fleet-owner-request") {
      setDropdowns({ userDropdown: false });
    } else {
      setDropdowns({ userDropdown: false });
    }
    // Do not close dropdown when navigating to "Active"
  };

  // ..........................................................

  const [dropdowns1, setDropdowns1] = useState({ userDropdown: false });

  const dropdownRef1 = useRef(null);

  useEffect(() => {
    if (location.pathname == "/driver") {
      setDropdowns1(() => ({
        userDropdown1: true,
      }));
    }
  }, [location]);

  useEffect(() => {
    const handleClickOutside1 = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setDropdowns1({ userDropdown1: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  const handleOptionSelect1 = (path) => {
    navigate(path);
    // If navigating to "New requests", close the dropdown
    if (path === "/driver") {
      setDropdowns1({ userDropdown1: false });
    } else {
      setDropdowns1({ userDropdown1: false });
    }
    // Do not close dropdown when navigating to "Active"
  };

  return (
    <aside>
      <div className="sidebar">
        <div className="logo-side">
          <Link to="/user">
            <img
              className="big"
              src={require("../../Assets/Images/login-logo.svg").default}
            />
          </Link>
        </div>
        <div className="side-menu">
          <div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14 21C13.4477 21 13 20.5523 13 20V12C13 11.4477 13.4477 11 14 11H20C20.5523 11 21 11.4477 21 12V20C21 20.5523 20.5523 21 20 21H14ZM4 13C3.44772 13 3 12.5523 3 12V4C3 3.44772 3.44772 3 4 3H10C10.5523 3 11 3.44772 11 4V12C11 12.5523 10.5523 13 10 13H4ZM9 11V5H5V11H9ZM4 21C3.44772 21 3 20.5523 3 20V16C3 15.4477 3.44772 15 4 15H10C10.5523 15 11 15.4477 11 16V20C11 20.5523 10.5523 21 10 21H4ZM5 19H9V17H5V19ZM15 19H19V13H15V19ZM13 4C13 3.44772 13.4477 3 14 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 20.5523 9 20 9H14C13.4477 9 13 8.55228 13 8V4ZM15 5V7H19V5H15Z"
                    fill="#696969"
                  />
                </svg>

                <p> Dashboard</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/passengers"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/passengers"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                    fill="#696969"
                  />
                </svg>

                <p> Passengers</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname === "/driver" ||
                  window.location.pathname === "/active-driver"
                    ? "menu-btn bar_active add-drop-down"
                    : "menu-btn add-drop-down"
                }
                to="/driver"
              >
                <div className="icon-and-name">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H4ZM13 16.083V20H17.6586C16.9423 17.9735 15.1684 16.4467 13 16.083ZM11 20V16.083C8.83165 16.4467 7.05766 17.9735 6.34141 20H11ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.2104 11 16 9.21043 16 7C16 4.78957 14.2104 3 12 3C9.78957 3 8 4.78957 8 7C8 9.21043 9.78957 11 12 11Z"
                      fill="#696969"
                    />
                  </svg>
                  <p>Drivers</p>
                </div>
                <div className="arrow-icon">
                  {dropdowns1.userDropdown1 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.9997 10.8276L16.9495 15.7773L18.3637 14.3631L11.9997 7.99912L5.63574 14.3631L7.04995 15.7773L11.9997 10.8276Z"
                        fill="#696969"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.9997 13.1724L16.9495 8.22266L18.3637 9.63687L11.9997 16.0009L5.63574 9.63687L7.04995 8.22266L11.9997 13.1724Z"
                        fill="#696969"
                      />
                    </svg>
                  )}
                </div>
              </Link>
              {/* Toggle visibility of dropdown based on state */}
              <div
                className={`dropdown-box ${
                  dropdowns1.userDropdown1 ||
                  location.pathname === "/active-driver"
                    ? "show"
                    : "hide"
                }`}
              >
                <ul>
                  <li
                    className={
                      location.pathname === "/driver" ? "active-btn" : ""
                    }
                    onClick={() => handleOptionSelect1("/driver")}
                  >
                    New requests
                  </li>
                  <li
                    className={
                      location.pathname === "/active-driver" ? "active-btn" : ""
                    }
                    onClick={() => handleOptionSelect1("/active-driver")}
                  >
                    Active
                  </li>
                </ul>
              </div>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname === "/fleet-owner-request" ||
                  window.location.pathname === "/active"
                    ? "menu-btn bar_active add-drop-down"
                    : "menu-btn add-drop-down"
                }
                to="/fleet-owner-request"
              >
                <div className="icon-and-name">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 20H5V21C5 21.5523 4.55228 22 4 22H3C2.44772 22 2 21.5523 2 21V11L4.4805 5.21216C4.79566 4.47679 5.51874 4 6.31879 4H17.6812C18.4813 4 19.2043 4.47679 19.5195 5.21216L22 11V21C22 21.5523 21.5523 22 21 22H20C19.4477 22 19 21.5523 19 21V20ZM20 13H4V18H20V13ZM4.17594 11H19.8241L17.6812 6H6.31879L4.17594 11ZM6.5 17C5.67157 17 5 16.3284 5 15.5C5 14.6716 5.67157 14 6.5 14C7.32843 14 8 14.6716 8 15.5C8 16.3284 7.32843 17 6.5 17ZM17.5 17C16.6716 17 16 16.3284 16 15.5C16 14.6716 16.6716 14 17.5 14C18.3284 14 19 14.6716 19 15.5C19 16.3284 18.3284 17 17.5 17Z"
                      fill="#696969"
                    />
                  </svg>
                  <p>Fleet owner</p>
                </div>
                <div className="arrow-icon">
                  {dropdowns.userDropdown ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.9997 10.8276L16.9495 15.7773L18.3637 14.3631L11.9997 7.99912L5.63574 14.3631L7.04995 15.7773L11.9997 10.8276Z"
                        fill="#696969"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.9997 13.1724L16.9495 8.22266L18.3637 9.63687L11.9997 16.0009L5.63574 9.63687L7.04995 8.22266L11.9997 13.1724Z"
                        fill="#696969"
                      />
                    </svg>
                  )}
                </div>
              </Link>
              {/* Toggle visibility of dropdown based on state */}
              <div
                className={`dropdown-box ${
                  dropdowns.userDropdown || location.pathname === "/active"
                    ? "show"
                    : "hide"
                }`}
              >
                <ul>
                  <li
                    className={
                      location.pathname === "/fleet-owner-request"
                        ? "active-btn"
                        : ""
                    }
                    onClick={() => handleOptionSelect("/fleet-owner-request")}
                  >
                    New requests
                  </li>
                  <li
                    className={
                      location.pathname === "/active" ? "active-btn" : ""
                    }
                    onClick={() => handleOptionSelect("/active")}
                  >
                    Active
                  </li>
                </ul>
              </div>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/vehicle-new-request"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/vehicle-new-request"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                    fill="#696969"
                  />
                </svg>

                <p>Vehicle Request</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.0049 22.0029C6.48204 22.0029 2.00488 17.5258 2.00488 12.0029C2.00488 6.48008 6.48204 2.00293 12.0049 2.00293C17.5277 2.00293 22.0049 6.48008 22.0049 12.0029C22.0049 17.5258 17.5277 22.0029 12.0049 22.0029ZM12.0049 20.0029C16.4232 20.0029 20.0049 16.4212 20.0049 12.0029C20.0049 7.58465 16.4232 4.00293 12.0049 4.00293C7.5866 4.00293 4.00488 7.58465 4.00488 12.0029C4.00488 16.4212 7.5866 20.0029 12.0049 20.0029ZM8.50488 14.0029H14.0049C14.281 14.0029 14.5049 13.7791 14.5049 13.5029C14.5049 13.2268 14.281 13.0029 14.0049 13.0029H10.0049C8.62417 13.0029 7.50488 11.8837 7.50488 10.5029C7.50488 9.12221 8.62417 8.00293 10.0049 8.00293H11.0049V6.00293H13.0049V8.00293H15.5049V10.0029H10.0049C9.72874 10.0029 9.50488 10.2268 9.50488 10.5029C9.50488 10.7791 9.72874 11.0029 10.0049 11.0029H14.0049C15.3856 11.0029 16.5049 12.1222 16.5049 13.5029C16.5049 14.8837 15.3856 16.0029 14.0049 16.0029H13.0049V18.0029H11.0049V16.0029H8.50488V14.0029Z"
                    fill="#696969"
                  />
                </svg>

                <p>Earning</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"
                    fill="#696969"
                  />
                </svg>

                <p>Pages</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 17.0022C21.999 19.8731 19.9816 22.2726 17.2872 22.8616L16.6492 20.9476C17.8532 20.7511 18.8765 20.0171 19.4649 19H17C15.8954 19 15 18.1046 15 17V13C15 11.8954 15.8954 11 17 11H19.9381C19.446 7.05369 16.0796 4 12 4C7.92038 4 4.55399 7.05369 4.06189 11H7C8.10457 11 9 11.8954 9 13V17C9 18.1046 8.10457 19 7 19H4C2.89543 19 2 18.1046 2 17V12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12V12.9987V13V17V17.0022ZM20 17V13H17V17H20ZM4 13V17H7V13H4Z"
                    fill="#696969"
                  />
                </svg>

                <p>Contact us</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 18H19V11.0314C19 7.14806 15.866 4 12 4C8.13401 4 5 7.14806 5 11.0314V18ZM12 2C16.9706 2 21 6.04348 21 11.0314V20H3V11.0314C3 6.04348 7.02944 2 12 2ZM9.5 21H14.5C14.5 22.3807 13.3807 23.5 12 23.5C10.6193 23.5 9.5 22.3807 9.5 21Z"
                    fill="#696969"
                  />
                </svg>

                <p>Notification</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M1 14.5C1 12.1716 2.22429 10.1291 4.06426 8.9812C4.56469 5.044 7.92686 2 12 2C16.0731 2 19.4353 5.044 19.9357 8.9812C21.7757 10.1291 23 12.1716 23 14.5C23 17.9216 20.3562 20.7257 17 20.9811L7 21C3.64378 20.7257 1 17.9216 1 14.5ZM16.8483 18.9868C19.1817 18.8093 21 16.8561 21 14.5C21 12.927 20.1884 11.4962 18.8771 10.6781L18.0714 10.1754L17.9517 9.23338C17.5735 6.25803 15.0288 4 12 4C8.97116 4 6.42647 6.25803 6.0483 9.23338L5.92856 10.1754L5.12288 10.6781C3.81156 11.4962 3 12.927 3 14.5C3 16.8561 4.81833 18.8093 7.1517 18.9868L7.325 19H16.675L16.8483 18.9868ZM13 13V17H11V13H8L12 8L16 13H13Z"
                    fill="#696969"
                  />
                </svg>
                <p>DB backup</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
