import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { forgetPass } from "../Redux/Actions/AdminAuth";

const validationschema = Yup.object().shape({
  email: Yup.string()
    .email(<span className="error-message">Invalid Email Address</span>)
    .required(<span className="error-message">Required</span>),
});

export default function ForgotPassword() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleForgetPass = (values) => {
    dispatch(forgetPass(values))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "error occur");
      });
  };

  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="center-bank-details">
                <div className="login-cmn-box">
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationschema}
                    onSubmit={handleForgetPass}
                  >
                    {({ values, handleSubmit, handleChange }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="otp-varification-page">
                          <div className="otp-heading">
                            <img
                              src={
                                require("../Assets/Images/login-logo.svg")
                                  .default
                              }
                            />
                            <h1>Forgot Password</h1>
                          </div>
                          <div className="bank-details-input">
                            <div className="user-info">
                              <Form.Group
                                className="mb-3 mt-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Enter your registered email"
                                  name="email"
                                  value={values?.email}
                                  onChange={handleChange}
                                />
                                <ErrorMessage name="email" component="div"/>
                              </Form.Group>
                            </div>
                          </div>
                          <button
                            variant="primary"
                            type="submit"
                            className="submit"
                          >
                            Send Verification Link
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
