import { createSlice } from "@reduxjs/toolkit";
import { passengerManagement } from "../Actions/PassengerAction";


export const Passengerslice = createSlice({
  name: "Passengerslice",
  initialState: {
    alldata: "",
    loading: false,
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(passengerManagement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(passengerManagement.fulfilled, (state, action) => {
        state.alldata = action.payload.data.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(passengerManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default Passengerslice.reducer;
