import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import {
  DriverApproveDecline,
  getRequestdriverDetails,
} from "../Redux/Actions/DriverActions";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { FaFilePdf } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import {
  AcceptDeclineFleetRequest,
  GetFleetRequestdetails,
} from "../Redux/Actions/FleetOwnerAction";

export default function FleetNewRequestsDetail() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [Fleetrequestdata, setfleetrequestdata] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [SelectedType, setSelectedType] = useState("");

  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(GetFleetRequestdetails({ fleetId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setfleetrequestdata(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, dispatch]);

  const ActionHandler = (type) => {
    if (type == 1) {
      dispatch(AcceptDeclineFleetRequest({ fleetId: id, type: type }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setShow(false);
            navigate("/fleet-owner-request");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(AcceptDeclineFleetRequest({ fleetId: id, type: type }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setShow(false);
            navigate("/fleet-owner-request");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const ActionHandlerHandleOpen = (type) => {
    if (type == 1) {
      setModalContent("Are you sure you want to Accept this request?");
      setShow(true);
      setSelectedType(1);
    } else {
      setShow(true);
      setModalContent("Are you sure you want to Decline this request?");
      setSelectedType(2);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field driver-new-req-det">
            <div className="driver-prof">
              <div className="driver-info">
                <div className="profile-image">
                  <img
                    src={
                      Fleetrequestdata?.profile_image
                        ? `${url}${Fleetrequestdata?.profile_image}`
                        : Logo
                    }
                  />
                  <h2>
                    {Fleetrequestdata?.company_name
                      ? `${Fleetrequestdata?.company_name}`
                      : "N/A"}
                  </h2>
                </div>
                <div className="driver-add">
                  <p>
                    <span> Email id :- </span>{" "}
                    {Fleetrequestdata?.company_email || "N/A"}
                  </p>
                  <p>
                    <span> Phone no. :- </span>{" "}
                    {Fleetrequestdata?.country_code &&
                    Fleetrequestdata?.phone_number
                      ? `${Fleetrequestdata.country_code} ${Fleetrequestdata.phone_number}`
                      : Fleetrequestdata?.country_code
                      ? Fleetrequestdata.country_code
                      : Fleetrequestdata?.phone_number
                      ? Fleetrequestdata.phone_number
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="approve-btn">
                <button onClick={() => ActionHandlerHandleOpen(1)}>
                  Accept
                </button>
                <button onClick={() => ActionHandlerHandleOpen(2)}>
                  Decline
                </button>
              </div>
            </div>
            <div className="documents-add">
              <h2>Documents</h2>
            </div>
            <div className="driver-documents-grid">
              <div className="documents-count">
                {Fleetrequestdata?.business_reg ? (
                  Fleetrequestdata?.business_reg.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Fleetrequestdata?.business_reg}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Fleetrequestdata?.business_reg.endsWith(".png") ||
                    Fleetrequestdata?.business_reg.endsWith(".jpg") ||
                    Fleetrequestdata?.business_reg.endsWith(".jpeg") ? (
                    <img
                      src={`${url}${Fleetrequestdata?.business_reg}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Bussiness Registaration</p>
              </div>
              <div className="documents-count">
                {Fleetrequestdata?.vat ? (
                  Fleetrequestdata?.vat.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Fleetrequestdata?.vat}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Fleetrequestdata?.vat.endsWith(".png") ||
                    Fleetrequestdata?.vat.endsWith(".jpg") ||
                    Fleetrequestdata?.vat.endsWith(".jpeg") ? (
                    <img
                      src={`${url}${Fleetrequestdata?.vat}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>VAT Identification Number</p>
              </div>
            </div>
            <br />
            <div className="driver-info">
              <div className="profile-image">
                <h2>Bank details:-</h2>
              </div>
              <div className="driver-add">
                <p>
                  <span> Bank account number :- </span>{" "}
                  {Fleetrequestdata?.account_number || "N/A"}
                </p>
                <p>
                  <span> Name of the bank :- </span>{" "}
                  {Fleetrequestdata?.bank_name || "N/A"}
                </p>

                <p>
                  <span> IBAN no. :- </span>{" "}
                  {Fleetrequestdata?.iban_number || "N/A"}
                </p>
                <p>
                  <span> BIC no. :- </span>{" "}
                  {Fleetrequestdata?.bic_number || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button
            className="new-secondary"
            variant="secondary"
            onClick={handleClose}
          >
            No
          </Button>

          {SelectedType === 2 ? (
            <Button
              className="new-danger"
              variant="danger"
              onClick={() => ActionHandler(2)}
            >
              Yes
            </Button>
          ) : (
            <Button
              className="new-danger"
              variant="danger"
              onClick={() => ActionHandler(1)}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
