import { configureStore } from "@reduxjs/toolkit";
import { Passengerslice } from "./Reducer/PasswngerSlice";
import { fleetownerslice } from "./Reducer/FleetownerSlice";
import { driverslice } from "./Reducer/DriverSlice";
import { vehicleSlice } from "./Reducer/VehicleSlice";



export const Store = configureStore({
  reducer: {
   passengers:Passengerslice.reducer,
   fleet:fleetownerslice.reducer,
   drivers:driverslice.reducer,
   vehicle:vehicleSlice.reducer
  },
});