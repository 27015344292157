import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { Button, Modal, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AcceptDeclineFleetRequest, GetFleetRequestdetails } from "../Redux/Actions/FleetOwnerAction";
import { useDispatch } from "react-redux";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { FaFilePdf } from "react-icons/fa6";
import { toast } from "react-toastify";

export default function FleetActiveDetail() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [Fleetrequestdata, setfleetrequestdata] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [SelectedType, setSelectedType] = useState("");

  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(GetFleetRequestdetails({ fleetId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setfleetrequestdata(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, dispatch]);


  const ActionHandler = (type) => {
    if (type == 1) {
      dispatch(AcceptDeclineFleetRequest({ fleetId: id, type: type }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setShow(false);
            navigate("/fleet-owner-request");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(AcceptDeclineFleetRequest({ fleetId: id, type: type }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setShow(false);
            navigate("/fleet-owner-request");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  const ActionHandlerHandleOpen = (type) => {
    if (type == 1) {
      setModalContent("Are you sure you want to Accept this request?");
      setShow(true);
      setSelectedType(1);
    } else {
      setShow(true);
      setModalContent("Are you sure you want to Decline this request?");
      setSelectedType(2);
    }
  };

  return (
    <Layout>
      <Container fluid className="g-0">
        <div className="active-fleet add-driver-bg-color">
          <div className="inner-filter-field ">
            <div className="driver-page-bg driver-new-req-det">
              <div className="driver-prof">
                <div className="driver-info">
                  <div className="profile-image">
                    <img
                      src={
                        Fleetrequestdata?.profile_image
                          ? `${url}${Fleetrequestdata?.profile_image}`
                          : Logo
                      }
                    />
                    <div>
                      <h2>
                        {" "}
                        {Fleetrequestdata?.company_name
                          ? `${Fleetrequestdata?.company_name}`
                          : "N/A"}
                      </h2>
                    </div>
                  </div>
                  <div className="driver-add">
                    <p>
                      <span> Email id :- </span>{" "}
                      {Fleetrequestdata?.company_email || "N/A"}
                    </p>
                    <p>
                      <span> Phone no. :- </span>{" "}
                      {Fleetrequestdata?.country_code &&
                      Fleetrequestdata?.phone_number
                        ? `${Fleetrequestdata.country_code} ${Fleetrequestdata.phone_number}`
                        : Fleetrequestdata?.country_code
                        ? Fleetrequestdata.country_code
                        : Fleetrequestdata?.phone_number
                        ? Fleetrequestdata.phone_number
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="approve-btn">
                  <button onClick={() => ActionHandlerHandleOpen(1)} >Block Fleet Owner</button>
                  <button onClick={() => ActionHandlerHandleOpen(2)}>Delete Fleet Owner</button>
                </div>
              </div>
              <div className="documents-add">
                <h2>Documents</h2>
              </div>
              <div className="driver-documents-grid">
                <div className="documents-count">
                  {Fleetrequestdata?.vat ? (
                    Fleetrequestdata?.vat.endsWith(".pdf") ? (
                      <a
                        href={`${url}${Fleetrequestdata?.vat}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf size={100} color="red" />
                      </a>
                    ) : Fleetrequestdata?.vat.endsWith(".png") ||
                      Fleetrequestdata?.vat.endsWith(".jpg") ||
                      Fleetrequestdata?.vat.endsWith(".jpeg") ? (
                      <img
                        src={`${url}${Fleetrequestdata?.vat}`}
                        alt="Driving License Front"
                      />
                    ) : (
                      <p>Unsupported file format</p>
                    )
                  ) : (
                    <p>Document not found</p>
                  )}
                  <p>VAT Identification Number</p>
                </div>
                <div className="documents-count">
                  {Fleetrequestdata?.business_reg ? (
                    Fleetrequestdata?.business_reg.endsWith(".pdf") ? (
                      <a
                        href={`${url}${Fleetrequestdata?.business_reg}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf size={140} color="red" />
                      </a>
                    ) : Fleetrequestdata?.business_reg.endsWith(".png") ||
                      Fleetrequestdata?.business_reg.endsWith(".jpg") ||
                      Fleetrequestdata?.business_reg.endsWith(".jpeg") ? (
                      <img
                        src={`${url}${Fleetrequestdata?.business_reg}`}
                        alt="Driving License Front"
                      />
                    ) : (
                      <p>Unsupported file format</p>
                    )
                  ) : (
                    <p>Document not found</p>
                  )}
                  <p>Bussiness Registaration</p>
                </div>
              </div>
            </div>

            <div className="car-driver">
              <div className="car-driving-box full-input-width ">
                <h2>Bank details</h2>
                <div className="car-name account-number-text mt-3">
                  <div className="bank-det-flex">
                    <span>Bank account number :-</span>
                    <p> {Fleetrequestdata?.account_number || "N/A"}</p>
                  </div>
                </div>
                <div className="car-name account-number-text">
                  <div className="bank-det-flex">
                    <span> Name of the bank :-</span>
                    <p> {Fleetrequestdata?.bank_name || "N/A"}</p>
                  </div>
                </div>
                <div className="car-name account-number-text">
                  <div className="bank-det-flex">
                    <span>IBAN no. :-</span>
                    <p> {Fleetrequestdata?.iban_number || "N/A"}</p>
                  </div>
                </div>
                <div className="car-name account-number-text">
                  <div className="bank-det-flex">
                    <span>BIC no. :-</span>
                    <p> {Fleetrequestdata?.bic_number || "N/A"}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="plat-form-fee">
              <p>Platform fees</p>
              <h3>
                $20/<span>month</span>
              </h3>
            </div>

            <div className="total-earnings-grid">
              <div className="earnings-text">
                <h2>Total earnings</h2>
                <p>
                  $500{" "}
                  <span>
                    {" "}
                    <img
                      src={require("../Assets/Images/eye-with-border.png")}
                    />
                  </span>
                </p>
              </div>
              <div className="earnings-text">
                <h2>Total earnings by cash</h2>
                <p>
                  $50
                  <span>
                    {" "}
                    <img
                      src={require("../Assets/Images/eye-with-border.png")}
                    />
                  </span>
                </p>
              </div>
              <div className="earnings-text">
                <h2>Tips</h2>
                <p>
                  $40{" "}
                  <span>
                    {" "}
                    <img
                      src={require("../Assets/Images/eye-with-border.png")}
                    />
                  </span>
                </p>
              </div>
            </div>

            <div className="car-driver">
              <div className="car-driving-box full-input-width ">
                <h2>Car location</h2>

                <div className="map-image-sec mt-4">
                  <img src={require("../Assets/Images/map-image.png")} />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button
            className="new-secondary"
            variant="secondary"
            onClick={handleClose}
          >
            No
          </Button>

          {SelectedType === 2 ? (
            <Button
              className="new-danger"
              variant="danger"
              onClick={() => ActionHandler(2)}
            >
              Yes
            </Button>
          ) : (
            <Button
              className="new-danger"
              variant="danger"
              onClick={() => ActionHandler(1)}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
