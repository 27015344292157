import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ResetPass } from "../Redux/Actions/AdminAuth";

const validationSchema = yup.object({
  newPassword: yup
  .string()
  .min(8, "New password must be at least 8 characters long")
  .matches(
    /[a-z]/,

    "Must contain at least one lowercase letter"
  )
  .matches(
    /[A-Z]/,

    "  Must contain at least one uppercase letter"
  )
  .matches(/[0-9]/, " Must contain at least one number")
  .matches(
    /[\W_]/,

    "Must contain at least one special character"
  )
  .required("Required"),
confirmpassword: yup
  .string()
  .oneOf([yup.ref("newPassword"), null], "Passwords must match")
  .required("Required"),
});

export default function ResetPassword() {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location?.search;
  const modifiedUrl = url ? url.substring(1) : "";

  const initialvalues = {
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="center-bank-details">
                <div className="login-cmn-box">

                <Formik
                    initialValues={initialvalues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      console.log(values, "values");

                      const payload = {
                        password: values?.newPassword,
                        token: modifiedUrl,
                      };
                      dispatch(ResetPass(payload))
                        .then((res) => {
                          console.log(res);
                          if (res?.payload?.data?.status === 200) {
                            toast.success(res?.payload?.data?.message);
                            setTimeout(() => {
                              toast.dismiss();
                              window.location.href = "/";
                            }, 1500);
                          } else {
                            toast.error(res?.payload?.data?.message);
                          }
                        })
                        .catch((error) => {
                          console.log(error, "error occurred");
                        });
                    }}
                  >
                    {({ values, handleSubmit, handleChange }) => (
                      <Form onSubmit={handleSubmit}>
                  <div className="otp-varification-page">
                    <div className="otp-heading">
                      <img
                        src={require("../Assets/Images/login-logo.svg").default}
                      />
                      <h1>Reset password</h1>
                    </div>
                    <div className="bank-details-input">
                      <div className="user-info">
                        <Form.Group
                          className="mb-3 mt-2"
                          controlId="formBasicEmail"
                        >
                          <Form.Control
                            type="password"
                            placeholder="New password"
                            name="newPassword"
                            value={values?.newPassword}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="newPassword" component="div"/>
                        </Form.Group>
                      </div>
                      <div className="user-info">
                        <Form.Group className="" controlId="formBasicEmail">
                          <Form.Control
                            type="password"
                            placeholder="Confirm password"
                            name="confirmPassword"
                            value={values?.confirmPassword}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <button variant="primary" type="submit" className="submit">
                      Update Password
                    </button>
                  </div>
                   </Form>
                  )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
